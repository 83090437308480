<template>
  <main class="login-page">
    <div class="login-container">
      <img class="is-small" src="../../assets/huddelArea-JPL-Logo.svg" alt="JPL Logo"/>
      <div class="upper">
        <h1>Login</h1>
      </div>
      <div class="form-container">
        <form @submit.prevent="login">
          <div class="field">
            <label class="label" for="login-email">Email Address:</label>
            <div class="control">
              <input tabindex="1" class="input" v-bind:class="{'is-danger': auth.emailError === true}" id="login-email" type="email" placeholder="" v-model="auth.email"/>
              <p class="help is-danger" v-if="auth.emailError === true">
                Please enter an email
              </p>
            </div>
          </div>
          <div class="field">
            <label class="label" for="login-pass">Password:</label>
            <div class="control">
              <input tabindex="2" class="input" v-bind:class="{'is-danger': auth.passwordError === true}" id="login-pass" type="password" autocomplete="true" v-model="auth.password"/>
              <p class="help is-danger" v-if="auth.passwordError === true">
                Please enter a password
              </p>
            </div>
          </div>
          <button tabindex="3" class="button is-info is-fullwidth" type="submit" :disabled="auth.email === '' || auth.password === ''" v-bind:class="{'is-loading': auth.loggingIn === true}">
            Login
          </button>
        </form>
      </div>
    </div>
    <div class="error-container p-4 mt-5" v-if="auth?.errors && auth?.errors.length >= 1">
      <p><font-awesome-icon icon="exclamation-triangle"></font-awesome-icon></p>
      <ul>
        <li v-for="e of auth.errors" :key="e">{{e}}</li>
      </ul>
    </div>
  </main>
</template>

<script>
// Import firebase and required modules of the library for this section of the app
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// Application router
import router from '../../router';

export default {
  name: 'login',
  props: ['post'],
  data() {
    return {
      auth: {
        // UI/UX elements
        loggingIn: false,
        email: '',
        password: '',
        message: '',
        hasErrors: false,
        emailError: false,
        passwordError: false,
        errors: [],
      },
    };
  },
  created() {
    this.auth.loggingIn = false;
    document.title = 'Huddle | Login';
  },
  methods: {
    async login() {
      this.auth.errors = [];
      this.auth.loggingIn = true;
      if (this.auth.email === '' || this.auth.password === '') {
        // Return errors
        this.auth.hasErrors = true;
        // Let's check sub items
        if (this.auth.email === '') {
          this.auth.emailError = true;
        }
        if (this.auth.password === '') {
          this.auth.passwordError = true;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.auth.email.includes('jpl')) {
          await firebase.auth().signInWithEmailAndPassword(this.auth.email, this.auth.password)
            .then((res) => {
              const adminRef = firebase.database().ref(`admins/${res.user.uid}`);
              adminRef.on('value', (snapshot) => {
                if (snapshot.exists()) {
                  const data = snapshot.val();
                  const userObj = {
                    uid: res.user.uid,
                    email: data?.email,
                    name: data?.name,
                    id: data?.id,
                    isClient: data?.isClient,
                    master: data?.master,
                  };
                  sessionStorage.setItem('admin', JSON.stringify(userObj));
                  if (userObj?.master === true) {
                    // Navigate to the admin screen
                    router.push('/admin/client-management');
                  }
                }
              });
            })
            .catch((error) => {
              switch (error.code) {
                case 'auth/invalid-email':
                  this.auth.errors.push('Invalid email');
                  break;
                case 'auth/user-not-found':
                  this.auth.errors.push('No account with that email was found');
                  break;
                case 'auth/wrong-password':
                  this.auth.errors.push('Incorrect password');
                  break;
                default:
                  this.auth.errors.push('Email or password was incorrect');
                  break;
              }
              this.auth.loggingIn = false;
              this.auth.email = '';
              this.auth.password = '';
            });
        } else {
          const clients = firebase.database().ref('clients');
          clients.on('value', (snapshot) => {
            if (snapshot.exists()) {
              const c = Object.entries(snapshot.val());
              const check = [];
              c.forEach((client) => {
                check.push(client[1]);
              });
              // Lets run a full check for email
              const emailCheck = check.find((item) => item.email === this.auth.email);
              if (emailCheck === undefined) {
                this.auth.errors.push('User does not exist');
                this.auth.loggingIn = false;
              } else {
                check.forEach((cl) => {
                  if (cl.email === this.auth.email) {
                    if (cl.isActive === true) {
                      if (this.auth.password === atob(cl.password)) {
                        sessionStorage.setItem('user', JSON.stringify(cl));
                        router.push('/admin/event-management');
                      } else {
                        this.auth.errors.push('Incorrect password');
                        this.auth.loggingIn = false;
                      }
                    } else {
                      this.auth.errors.push('User not authenticated');
                      this.auth.loggingIn = false;
                    }
                  }
                });
              }
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .login-page {
    min-width: 100vh;
    height: 100vh;

    padding: 0;
    margin: 0;

    background: #F1F1F1;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    .login-container {
      width: 100%;
      max-width: 900px;
      height: auto;

      padding: 20px;
      margin: 0;

      border: 1px solid lightgray;
      //border-radius: 5px;
      border-radius: clamp(0px, ((100vw - 4px) - 100%) * 9999, 8px);
      box-shadow: 0 10px 15px #d0cfd0;
      background: #FFFFFF;

      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 200px;
      }

      .upper {
        width: 100%;
        height: auto;

        h1 {
          font: 500 2rem "Helvetica", sans-serif;

          color: #2AA8E0;
          border-bottom: 2px solid #333;

          padding-bottom: 10px;
          margin-bottom: 11px;
        }

        h2 {
          font: 400 1.1rem "Helvetica", sans-serif;
        }
      }
    }

    .form-container {
      width: 100%;
      height: auto;

      padding: 13px 0 13px 0;
      margin: 0;

      form {
        label {
          font: 600 0.875rem sans-serif;
          color: #333;
        }
      }
    }

    .error-container {
      width: 100%;
      max-width: 900px;
      height: auto;

      background-color: #c23616;
      color: #FFFFFF;

      border-radius: 5px;

      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
</style>
